<template>
    <div class="row justify-content-center mt-3">
        <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-7">
      <div v-if='validatedApp' class="claimelly mx-auto" :class='[(mode) ? "light" : "dark"]'
    >
  <div class="position-ralative" v-if='setupComplete'>
        <p class="position-absolute c-text" v-if='!expired'
        :class='[(mode) ? "text-whites" : "text-darks"]'
        style='    bottom: 11px;
      font-size: 20px;'
        >
         <span v-if="expireable">
          Claim Period Expiring In: 
         <span class='orbit'>
         {{days}}d {{hours}}h {{minutes}}m {{seconds}}s
  
         </span>
         </span>
        </p>
        <p class="position-absolute"
        :class='[(mode) ? "text-whites" : "text-darks"]'
         v-else
        style='    bottom: 11px;
      font-size: 20px;'
        >
         Claim Period Has Been Expired
        </p>
        <button class="position-absolute btn btn-primary-outline btn-mode" @click='mode = !mode'>
          <img v-if='mode' src="/img/sun-color.png" width="103" height="40" alt="light">
          <img v-else src="/img/moon-black.png" width="103" height="40" alt="light">
        </button>
      </div>  
      <div class="position-relative" >
          <img src="/img/icon.png" style='width:300px;height:42px;' class='d-none d-lg-block icon link' @click='goTo' alt="icon">
          <img src="/img/icon.png" style='width:200px;height:50px;' class='d-lg-none icon-mob link' @click='goTo' alt="icon">
          <div class="hr float-right d-none d-lg-block"></div>
          <span class="btn-pri position-absolute d-none d-lg-block" @click='connect'>
          {{selectedAccount?(selectedAccount.substr(0,7)+"....."+selectedAccount.substr(-8)):"Connect Wallet"}}
          </span>
          <span class="btn-pri-mob position-absolute d-lg-none" @click='connect'>
          {{selectedAccount?(selectedAccount.substr(0,7)+"....."+selectedAccount.substr(-8)):"Connect Wallet"}}
          </span>
          
      </div>
      <div v-if='setupComplete'>
  
      
      <div v-if='!isAdmin && selectedAccount'>
            <h1 class="text-center txBgPri" style='margin-top:  5rem;' v-if='loading'>Loading</h1>
            <h1 class="text-center txBgPri" style='margin-top:  5rem;' v-else>Claim</h1>
          <div class="row justify-content-center">
            <div class="col-12 col-lg-10 inp-border border-0 py-3 px-4"
            :class='[(mode) ? "light" : "dark"]'
            >
                <h2 :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]' class=" border-0 mb-0 p-0 float-left " id="">
                  {{claimData.claims[selectedAccount] ? parseInt(claimData.claims[selectedAccount]['amount'],16)/1000000000000000000 : "00.00"}}
                </h2>
                <h2 :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]' class=" border-0 mb-0 p-0 float-right text-right" style='width:155px;' id="">{{symbol}}</h2>
            </div>
            <div class="col-12 col-lg-10" v-if='claimData.claims[selectedAccount] !== undefined'>
              <h2 class="my-5" :class='[(mode) ? "text-whites" : "text-darks"]' >Available to Claim</h2>
            </div>
            <div class="col-12 col-lg-10" v-else>
              <h2 class="my-5" :class='[(mode) ? "text-whites" : "text-darks"]' > Not Available to Claim</h2>
            </div>
  
            <div class="col-12 col-lg-10" v-if='claimData.claims[selectedAccount] !== undefined'>
              <span class="btn-pri d-block mx-auto"
              v-if='!expired'
              @click='claim(parseInt(claimData.claims[selectedAccount]["amount"],16)/1000000000000000000)'>{{isClaimDone ? "Already Claimed" : "Claim Now"}}
              </span>
            </div>
            <div class="col-12 col-lg-10" v-else>
              
            </div>
          </div>
  
        </div>
      <div v-else-if='isAdmin && selectedAccount' style='margin-top:5rem;'>
  
        <div v-if='selectedAccount == admin || selectedAccount == owner'>
          <div class="d-none d-sm-block">
          <p class='text-center det_spec mb-0'>Contract: <b>{{CONTRACT_ADDRESS}} 
            <span class='btn btn-link px-0' @click='copy(CONTRACT_ADDRESS)'>&#x2398;</span>
              </b></p>
          <p class='text-center det_spec mb-0'>Admin: <b>{{admin}} 
            <span class='btn btn-link px-0' @click='copy(admin)'>&#x2398;</span>
              </b></p>
          <p class='text-center det_spec mb-0'>Owner: <b>{{owner}} 
            <span class='btn btn-link px-0' @click='copy(owner)'>&#x2398;</span>
              </b></p>    
          </div>
          <div class="d-sm-none">
            <p class='text-center det_spec-mob mb-0'>Contract: <b>{{FCONTRACT_ADDRESS}} 
              <span class='btn btn-link px-0' @click='copy(CONTRACT_ADDRESS)'>&#x2398;</span>
                </b></p>
            <p class='text-center det_spec-mob mb-0'>Admin: <b>{{Fadmin}} 
              <span class='btn btn-link px-0' @click='copy(admin)'>&#x2398;</span>
                </b></p>
            <p class='text-center det_spec-mob mb-0'>Owner: <b>{{Fowner}} 
              <span class='btn btn-link px-0' @click='copy(owner)'>&#x2398;</span>
                </b></p>    
          </div>
      </div> 
      <div class="row justify-content-center">
          <div class="col-12 col-lg-10 inp-border border-0 shadow py-0 px-0 mt-3"
          v-if='selectedAccount == admin'
          >
  
            <div class="input-group shadow-cc">
              <div class="input-group-prepend">
                <span class="input-group-text shadow-c d-inline-block" 
                :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
                  style='width:135px;border-radius:22px;' id="">Select Token:</span>
              </div>
              <select name="" id="" v-model='wToken' class='form-control inp-border border-0 ml-1'
              :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
              >
                <option
                :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
                 :value="null" disabled> Please Select a Token </option>
                <option 
                :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
                v-for='token in availableTokens' v-if='token.currency.symbol !== "BNB"' :key='token.currency.address' :value="token.currency.address">
                  <span class="float-left"> {{token.currency.symbol}} </span>
                  <span class="float right">  (<b>{{token.value}}</b>) </span>
                  
                  
                  </option>
              </select>
              <!-- <input type="text" placeholder='Address' class="form-control inp-plain" v-model='wToken'> -->
            </div>
            
  
          </div>
            <div class="col-12 col-lg-10 inp-border border-0 shadow py-0 px-0 mt-3"
            v-if='selectedAccount == admin'
            >
  
            <div class="input-group shadow-cc">
              <div class="input-group-prepend">
                <span class="input-group-text shadow-c d-inline-block"
                :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
                  style='width:135px;border-radius:22px;' id="">Amount:</span>
              </div>
              <input type="number" placeholder='0' 
              :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
                class="form-control inp-plain" v-model='wAmount'>
              <div class="input-group-append"
              :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
              >
                <span v-if='wAmount < 1 && !validateWToken'
                class="input-group-text btn-drk d-inline-block" style='width:135px;border-radius:22px;' id="">Withdraw</span>
                <span v-else @click='withdraw' class="input-group-text btn-drks d-inline-block" style='width:135px;border-radius:22px;' id="">Withdraw</span>
  
              </div>
            </div>
          </div>
          <!-- <div class="col-12 col-lg-10 mb-3">
            <p class="m-0 p-0 text-danger float-left" v-if='validateWToken'><i>Please add a valid address</i></p>
          </div> -->
  
          <div class="col-12 col-lg-10 inp-border border-0 shadow py-0 px-0 mt-3"
          v-if='selectedAccount == admin || selectedAccount == owner'
          >
  
            <div class="input-group shadow-cc d-none">
              <div class="input-group-prepend">
                <span class="input-group-text shadow-c d-inline-block"
                :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
                  style='width:135px;border-radius:22px;' id="">Fund Amount:</span>
              </div>
              <input type="number" placeholder='0' 
              :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
                class="form-control inp-plain" v-model='fAmount'>
              <div class="input-group-append">
                <span v-if='fAmount < 1'
                class="input-group-text btn-drk d-inline-block" style='width:135px;border-radius:22px;' id="">Fund Contract</span>
                <span v-else @click='fund' class="input-group-text btn-drks d-inline-block" style='width:135px;border-radius:22px;' id="">Fund Contract</span>
              </div>
            </div>
          
          </div>
  
          <div class="col-12 col-lg-10 inp-border border-0 shadow py-0 px-0 mt-3 d-none d-lg-block"
          v-if='selectedAccount == owner'
          >
  
            <div class="input-group shadow-cc">
              <div class="input-group-prepend">
                <span class="input-group-text shadow-c d-inline-block"
                :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
                  style='width:135px;border-radius:22px;' id="">Change Admin:</span>
              </div>
              <input type="text" placeholder='Enter a valid Wallet Address' 
              :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
                class="form-control inp-plain" v-model='changeAdmin'>
              <div class="input-group-append">
                <span v-if='validatedChangeAdmin'
                class="input-group-text btn-drk d-inline-block" style='width:135px;border-radius:22px;' id="">Update</span>
                <span v-else @click='updateAdmin' class="input-group-text btn-drks d-inline-block" style='width:135px;border-radius:22px;' id="">Update</span>
              </div>
            </div>
            
          
          </div>
          <div class="col-12 col-lg-10  py-0 px-0 mt-3 d-lg-none"
          v-if='selectedAccount == owner'
          >
          <div class="form-group"
          :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
          >
              <label for=""> Change Admin </label>
              <input type="text" name="" id=""
              :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
                placeholder='Enter a valid Wallet Address' v-model='changeAdmin' class="form-control">
          </div>
          <div class="form-group">
                <span v-if='validatedChangeAdmin'
                class="input-group-text shadow-c btn-drk d-inline-block" style='width:135px;border-radius:22px;' id="">Update</span>
                <span v-else @click='updateAdmin' class="input-group-text btn-drks d-inline-block" style='width:135px;border-radius:22px;' id="">Update</span>
              
              </div>
          </div>
  
  
          <div class="col-12 col-lg-10 inp-border border-0 shadow py-0 px-0 mt-3"
          v-if='selectedAccount == admin'
          >
  
            <div class="input-group shadow-cc">
              <div class="input-group-prepend">
                <span class="input-group-text shadow-c d-inline-block"
                :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
                  style='width:135px;border-radius:22px;' id="">Withdraw {{symbol}}:</span>
              </div>
              <input type="number"
              :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
                :placeholder='"withdraw all "+claimAccountBalance+" "+symbol' disabled  class="inp-dis form-control inp-plain" v-model='wToken'>
              <div class="input-group-append">
                  <span v-if='!claimAccountBalance'
                class="input-group-text btn-drk d-inline-block" style='width:135px;border-radius:22px;' id="">Withdraw</span>
                <span v-else @click='withdrawAll' class="input-group-text btn-drks d-inline-block" style='width:135px;border-radius:22px;' id="">Withdraw</span>
              
                <!-- <span @click="withdrawAll" class="input-group-text btn-drks d-inline-block" style='width:135px;border-radius:22px;' id="">withdraw</span> -->
              </div>
            </div>
          
          </div>
          <div class="col-12 col-lg-10 inp-border border-0 shadow py-0 px-0 mt-3"
          v-if='selectedAccount == admin'
          >
          <div class="input-group shadow-cc">
              <div class="input-group-prepend">
                <span class="input-group-text shadow-c d-inline-block"
                :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
                  style='width:135px;border-radius:22px;' id="">Withdraw BNB:</span>
              </div>
              <input type="number"
              :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
                :placeholder='"withdraw all "+claimAccountNativeBalance' disabled  class="inp-dis form-control inp-plain" v-model='wToken'>
              <div class="input-group-append">
                <span v-if='!claimAccountNativeBalance'
                class="input-group-text btn-drk d-inline-block" style='width:135px;border-radius:22px;' id="">Withdraw</span>
                <span v-else @click='withdrawNative' class="input-group-text btn-drks d-inline-block" style='width:135px;border-radius:22px;' id="">Withdraw</span>
              
                <!-- <span @click="withdrawNative" class="input-group-text btn-drks d-inline-block" style='width:135px;border-radius:22px;' id="">withdraw</span> -->
              </div>
            </div>
  
                <!-- <span class="btn-prim-big" style='border-radius:22px;' id="" @click='withdrawNative'>Withdraw</span> -->
          </div>
          <div class="col-12 col-lg-10 inp-border border-0 shadow py-0 px-0 mt-3 d-none d-lg-block"
          v-if='selectedAccount == admin || selectedAccount == owner'
          >
  
            <div class="input-group shadow-cc">
              <div class="input-group-prepend">
                <span class="input-group-text shadow-c d-inline-block"
                :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
                  style='width:135px;border-radius:22px;' id="">Check Claim:</span>
              </div>
              <input type="text" placeholder='Enter a valid Wallet Address'
              :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
                class="form-control inp-plain" v-model='vCheck'>
              <div class="input-group-append">
                <span v-if='validatedWalletCheck'
                class="input-group-text btn-drk d-inline-block" style='width:135px;border-radius:22px;' id="">Check</span>
                <span v-else @click="checkValue" class="input-group-text btn-drks d-inline-block" style='width:135px;border-radius:22px;' id="">Check</span>
              </div>
            </div>
        <div v-if='walletCheckResults' v-html='walletCheckResults'></div>
          
          </div>
          <div class="col-12 col-lg-10  py-0 px-0 mt-3 d-lg-none"
          v-if='selectedAccount == admin || selectedAccount == owner'
          >
          <div class="form-group"
          :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
          >
              <label for=""> Claim Check </label>
              <input type="text" name="" id=""
              :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
                placeholder='Enter a valid Wallet Address' v-model='vCheck' class="form-control">
          </div>
          <div class="form-group"
          :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
          >
                <span v-if='validatedWalletCheck'
                class="input-group-text btn-drk d-inline-block" style='width:135px;border-radius:22px;' id="">Check</span>
                <span v-else @click='checkValue' class="input-group-text btn-drks d-inline-block" style='width:135px;border-radius:22px;' id="">Check</span>
              </div>
          </div>
        <div v-if='walletCheckResults' v-html='walletCheckResults'></div>
  
          
  
  
        </div> 
    </div>
  
      <div class="position-relative" v-if='selectedAccount'>
        <button v-if='selectedAccount == admin || selectedAccount == owner' class="btn btn-link position-absolute btn-tab" @click='isAdmin = !isAdmin'> 
          {{ (isAdmin) ? "User" : "Admin" }}
          
          </button>
      </div>
      </div>
      <div v-else-if='!setupComplete' style='margin-top:5rem;'>
        <div v-if='cStep == null'>
          <h2 class='text-center'
        :class='[(mode) ? "text-whites" : "text-darks"]'>
          Welcome to Claimelly
        </h2>
        <h3 class='text-center'
        :class='[(mode) ? "text-whites" : "text-darks"]'>
          Please connect your wallet to Start
        </h3>
        </div>
        <div class="row">
          <div class="col-12" v-if='selectedAccount && cStep == 1'>
            <h2 class='text-center'
            :class='[(mode) ? "text-whites" : "text-darks"]'>
            Welcome to the Claim Service Setup
            </h2>
            <h3 class='text-center'
            :class='[(mode) ? "text-whites" : "text-darks"]'>
              Please Upload CSV File <span class="btn btn-link" @click='loadSampleImage'>View Sample</span>
            </h3>
            <div>
              <div class="custom-file">
                <input type="file" class="custom-file-input" accept=".csv" @change='handleFile' id="validatedCustomFile" required>
                <label class="custom-file-label" for="validatedCustomFile">Choose file...</label>
                <div class="invalid-feedback">Example invalid custom file feedback</div>
              </div>
            </div>
            <div>
              <button class="btn btn-primary mt-4" @click='stepBack(0)'> Back to Token Info</button>
  
            </div>
          </div>
          <div class="col-12" v-if='selectedAccount && cStep == 0'>
            <h5 class='text-center'
            :class='[(mode) ? "text-whites" : "text-darks"]'
            >Please provide below Information<br>
            Note:Provided Information will not be changed
            </h5>
            <div class="form-group">
              <label :class='[(mode) ? "text-whites" : "text-darks"]' for="Chain">Select Network</label>
              <select name="" id="" v-model='selectedChain' class="form-control">
                <option v-for='chain in chains' :key='chain.chainId' :value="chain">
                  {{chain.name}}
                </option>
              </select>
            </div>
            <div class="form-group" v-if='selectedChain'>
              <label :class='[(mode) ? "text-whites" : "text-darks"]' for="">Token</label>
               <input type="text" name="" id="" @blur="validateWallet" v-model='selectedToken' class="form-control">
            </div>
            <div class="form-group" v-if='selectedChain'>
              <label :class='[(mode) ? "text-whites" : "text-darks"]' for="">Decimals</label>
              <input type='number' class="form-control" :disabled='!manualInput' v-model='selectedDecimal' >
            </div>
            <div class="form-group" v-if='selectedChain'>
              <label :class='[(mode) ? "text-whites" : "text-darks"]' for="">Token Symbol</label>
              <!-- <p class="form-control" :disabled='!manualInput'>{{userAccount.symbol}}</p> -->
              <input type='text' minlength="3" maxlength="5" class="form-control" :disabled='!manualInput' v-model='selectedSymbol' >
            </div>
            <div class="form-group">
              <button class="btn btn-primary" @click='saveTokenChanges'> Save Changes </button>
            </div>
          </div>
          <div class="col-12" v-if='selectedAccount && cStep == 2'>
            <h5 class='text-center'
            :class='[(mode) ? "text-whites" : "text-darks"]'>
              Contract is Ready For Deployment Now <br>
              Please Confirm Below Details and click Deploy
            </h5>
            <p :class='[(mode) ? "text-whites" : "text-darks"]' v-if='userAccount.selectedChain !== null'>
              Network Chain Selected: 
              <b class="float-right"> {{userAccount.selectedChain.name}} </b>
            </p>
            <p :class='[(mode) ? "text-whites" : "text-darks"]'>
              Token Contract: 
              <b class="float-right"> {{userAccount.TOKEN}} </b>
            </p>
            
            <p :class='[(mode) ? "text-whites" : "text-darks"]'>
              Token Symbol: 
              <b class="float-right"> {{userAccount.symbol}} </b>
            </p>
            <p :class='[(mode) ? "text-whites" : "text-darks"]'>
              Token Deciamls: 
              <b class="float-right"> {{userAccount.DECIMALS}} </b>
            </p>
            <p :class='[(mode) ? "text-whites" : "text-darks"]'>
              Total Claimable Wallets: 
              <b class="float-right"> {{totalClaimWallets}} </b>
            </p>
            <p :class='[(mode) ? "text-whites" : "text-darks"]'>
              Total Claimable Amount: 
              <b class="float-right"> {{totalClaimBalance}} </b>
            </p>
            <p :class='[(mode) ? "text-whites" : "text-darks"]'>
              Owner/Admin of the Distribution Contract: 
              <b class="float-right"> {{userAccount.owner}} </b>
            </p>
            <div class="p-2">
            <button class="btn btn-primary mt-3 float-left" @click='stepBack(1)'> Back </button>
            <button class="btn btn-primary mt-3 float-right" @click='confirmDeploy'> Confirm Details & Deploy </button>
            
            </div>
            
          </div>
          <div class="col-12" v-if='selectedAccount && cStep == 3'>
            <h5 class='text-center btn btn-link'
            :class='[(mode) ? "text-whites" : "text-darks"]' @click='goToScan'>
            Contract: {{userAccount.CONTRACT}}
            </h5>
            <h5 class='text-center'
            :class='[(mode) ? "text-whites" : "text-darks"]'>
              Distribution Contract has been deployed and <br> 
              ready to use
              <br>
              Please select Start and End date or leave blank for no expiry period
            </h5>
             <div>
            <label :class='[(mode) ? "text-whites" : "text-darks"]' for="start-date">Choose a start date</label>
            <b-form-datepicker id="start-date" v-model="startDate" class="mb-2"></b-form-datepicker>
            <label :class='[(mode) ? "text-whites" : "text-darks"]' for="end-date">
              Choose a end date(Please choose any future date greater then Start Date)
              </label>
            <b-form-datepicker id="end-date" v-model="endDate" class="mb-2"></b-form-datepicker>
            <button class="btn btn-primary" @click='saveTimeChanges'>
              Save Changes
            </button>
          </div>
  
            </div>
          <div class="col-12" v-if='selectedAccount && cStep == 4'>
            <h5 class='text-center'
            :class='[(mode) ? "text-whites" : "text-darks"]'>
              It is all ready Now, Soon App Will be published  <br> 
              <!-- OR Click Reset to reset data -->
              <br>
              <!-- Note:Resetting the setup will cause wipe all date provided yet -->
            </h5>
              
            </div>  
        </div>
      </div>
    <b-modal id="sample-image" title="CSV File Upload Checks Summary">
      <img src="/img/sample-csv.png" class='w-100' alt="" srcset="" >
    </b-modal>
    <b-modal id="import-result"
     size='lg'
     title="CSV File Upload Checks Summary"
     cancel-title="Reupload"
     ok-title="Generate List"
     @ok="generateRoot"
      >
      <h2>Summary</h2>
      <h5 class='text-center'>Errors</h5>
      <p class="mb-0" v-for='(error, index) in errors' :key='index'>{{error}}</p>
      <h5 class='text-center'>Wallet Skipped</h5>
      <p class="mb-0" v-for='(error, index) in skipped' :key='index'>{{error}}</p>
      <button class="btn btn-primary" @click='showDetails = !showDetails'> 
      {{(showDetails) ? "Hide Details" : "Show Added Wallets"}}
      </button>
       <table class='table' v-if='showDetails'>
        <thead>
          <tr>
            <th>Wallet</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for='(wallet, key) in csvData' :key='key'>
            <td>
              {{key}}
            </td>
            <td>
              {{parseInt(csvData[key],16)/10**DECIMALS}} {{symbol}}
              
            </td>
          </tr>
        </tbody>
      </table> 
      
      
    </b-modal>
    <b-modal id="all-details"
     size='lg'
    ok-only 
    title="Distrubution Contract Summary"
      >
      <h2>Summary</h2>
      <div v-if='userAccount'>
        
            <p v-if='userAccount.selectedChain !== null'>
              Network Chain Selected: 
              <b class="float-right"> {{userAccount.selectedChain.name}} </b>
            </p>
            <p >
              Distrubution Contract Address: 
              <b class="float-right"> {{userAccount.CONTRACT}} </b>
            </p>
           <p >
              Token Contract Address: 
              <b class="float-right"> {{userAccount.TOKEN}} </b>
            </p>
            <p >
              Distrubution Start Date: 
              <b class="float-right"> {{userAccount.startTime}} </b>
            </p>
            <p >
              Distrubution End Date: 
              <b class="float-right"> {{userAccount.endTime}} </b>
            </p>
            
            <p >
              Token Symbol: 
              <b class="float-right"> {{userAccount.symbol}} </b>
            </p>
            <p >
              Token Deciamls: 
              <b class="float-right"> {{userAccount.DECIMALS}} </b>
            </p>
            <p >
              Total Claimable Wallets: 
              <b class="float-right"> {{totalClaimWallets}} </b>
            </p>
            <p >
              Total Claimable Amount: 
              <b class="float-right"> {{totalClaimBalance}} </b>
            </p>
            <p >
              Owner/Admin of the DIstrubition Contract: 
              <b class="float-right"> {{userAccount.owner}} </b>
            </p>
      <button class="btn btn-primary" @click='showWallets = !showWallets'> 
      {{(showWallets) ? "Hide Wallets" : "Show Wallets"}}
      </button>
       <table class='table' v-if='showWallets'>
        <thead>
          <tr>
            <th>Wallet</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for='(wallet, key) in claimWallets' :key='key'>
            <td>
              {{key}}
            </td>
            <td>
              <!-- {{wallet}}{{key}} -->
              {{parseInt(wallet.amount,16)/10**DECIMALS}} {{symbol}}
              
            </td>
          </tr>
        </tbody>
      </table> 
      </div>
      
      
    </b-modal>
      </div>
      <div v-if='validatedApp == null'>
          <h3 class='text-center'> Please wait, App is being Validated </h3>
      </div>
      <div v-if='validatedApp == false'>
          <h3 class='text-center'> {{validationError}} </h3>
      </div>
    </div>
    </div>
    
  </template>
  
  <script>
  import Web3 from 'web3';
  const web3 = new Web3(Web3.givenProvider);
  import detectEthereumProvider from '@metamask/detect-provider';
  import {mapState} from 'vuex';
  import { parseBalanceMap } from '../parse-balance-map.ts'
  // import { parse } from 'csv-parse';
  export default {
    computed:{
      ...mapState(["selectedAccount","validationError","endTime","startTime","userAccount","DECIMALS","cStep",
      'setupComplete','symbol','availableTokens','claimAccountNativeBalance','CONTRACT_ADDRESS',
      'claimable','isClaimDone','accounts','claimData','claimAccountBalance','TokenBalance','owner','admin']),
      totalClaimBalance(){
        if (this.userAccount.merkleData) {
        let data = JSON.parse(this.userAccount.merkleData)
        return (parseInt(data.tokenTotal,16)/10**this.DECIMALS+" "+this.symbol)
        }
      },
      totalClaimWallets(){
        if (this.userAccount.merkleData) {
        let data = JSON.parse(this.userAccount.merkleData)
        let count = Object.keys(data.claims);
        return count.length 
        }
      },
      claimWallets(){
        if (this.userAccount.merkleData) {
        let data = JSON.parse(this.userAccount.merkleData)
        return data.claims
        }
      },
      account(){
        return this.$store.state.registeredWallets[this.selectedAccount].claims[this.appId]
      },
      validateWToken(){
        let cond = web3.utils.isAddress(this.wToken)
        if (cond) {
          return false;
        } else {
          return true
        }
      },
      getClass(){
        return (this.mode) ? "light" : "dark";
      },
      validatedChangeAdmin(){
        let cond = web3.utils.isAddress(this.changeAdmin)
        if (cond) {
          return false;
        } else {
          return true
        }
      },
      validatedWalletCheck(){
        let cond = web3.utils.isAddress(this.vCheck)
        if (cond) {
          return false;
        } else {
          return true
        }
      },
      FCONTRACT_ADDRESS(){
        return this.CONTRACT_ADDRESS.substr(0,7)+"....."+this.CONTRACT_ADDRESS.substr(-8)
      },
      Fadmin(){
        return this.admin.substr(0,7)+"....."+this.admin.substr(-8)
      },
      Fowner(){
        return this.owner.substr(0,7)+"....."+this.owner.substr(-8)
      },
    },
    data() {
      return {
        portal:null,
        chains:[
          {
            name:"Ethereum Main Network",
            chainId:"0x1",
            KEY:'3MBSDFSIQZ1B9WWYI1V7GY4MX9QFQE83XZ',
            API:"https://api.etherscan.io/"
          },
          {
            name:"Rinkeby Test Network",
            chainId:"0x4",
            KEY:'3MBSDFSIQZ1B9WWYI1V7GY4MX9QFQE83XZ',
            API:"https://api-rinkeby.etherscan.io/"
          },
          {
            name:"Binance Main Network",
            chainId:"0x38",
            KEY:'YU1IGKUWQDN49FP4T3YGV97VDV56HVG7AW',
            API:"https://api.bscscan.com/"
          },
          {
            name:"Binance Test Network",
            chainId:"0x61",
            KEY:'YU1IGKUWQDN49FP4T3YGV97VDV56HVG7AW',
            API:"https://api-testnet.bscscan.com/"
          }
        ],
        startDate:null,
        endDate:null,
        selectedChain:null,
        showDetails:false,
        csvData:null,
        loading:false,
        mode:false,
      fundAmount:0,
      walletCheckResults:null,
      vCheck:'',
      isAdmin:false,
      manualInput:false,
        changeAdmin:'',
        days:'',
        hours:'',
        web3:null,
        days:'',
        hours:'',
        minutes:'',
        seconds:'',
        countDownDate:new Date("Aug 23, 2022 4:14:25").getTime(),
        distance:null,
        now:null,
        expired:false,
        expireable:false,
        wAmount:0,
        fAmount:0,
        wAddress:null,
        wToken:null,
        fileinput:null,
        errors:[],
        selectedToken:null,
        skipped:[],
        isWalletValid:null,
        selectedSymbol:null,
        selectedDecimal:null,
        showWallets:false,
        provider:null,
        validatedApp:null,
        appId:"0x3107f81e9d50a791718776a083eF529ED0b54252"
        // wToken:"0x542f71A83dd4c11a56a9071d7301D6AdbFf9E346",
      }
    },
    
    async mounted() {
      // await this.$store.dispatch("loadAccounts")
      await this.checkParams()
      // if (this.selectedAccount) {
        
      // }
  },
    methods:{
      stepBack(step){
          if (step == 0) {
          this.$store.state.registeredWallets[this.selectedAccount].claims[this.appId].DECIMALS = null
          this.$store.state.registeredWallets[this.selectedAccount].claims[this.appId].symbol = null
          this.$store.state.registeredWallets[this.selectedAccount].claims[this.appId].TOKEN = null
          this.$store.state.registeredWallets[this.selectedAccount].claims[this.appId].selectedChain = null
          }
        this.$store.dispatch("stepBack", {step, id:this.appId})
  
      },
      resetApp(){
        let dec = confirm("Are you sure? this will cause wipe of all current data plus loss of deployed smart contract")
        if (dec) {
          this.$store.dispatch("resetApp", this.appId)
        }
      },
      goToScan(){
        let network = '';
         if (this.userAccount.selectedChain.chainId == "0x1") network = "https://etherscan.io/address/"
        if (this.userAccount.selectedChain.chainId == "0x4") network = "https://rinkeby.etherscan.io/address/"
        if (this.userAccount.selectedChain.chainId == "0x38") network = "https://bscscan.com/address/"
        if (this.userAccount.selectedChain.chainId == "0x61") network = "https://testnet.bscscan.com/address/"
        window.open(network+this.userAccount.CONTRACT, '_blank').focus();
  
      },
      async checkParams(){
        let params = this.$route.params;
        this.appId = params.id
        // this.portal = params.location
        // if (this.portal ==  "portal") {
        //   this.connect()
        // }
      let dec = await this.$store.dispatch("loadAppConfig", {id: this.appId})
      console.log("dec is ", dec)
      if (dec.status) 
      {
        this.validatedApp = true
        if (this.startTime && this.endTime) {
          await this.timer();        
        }
  
      }
      else this.validatedApp = false
  
      },
      startApp(){
        this.$store.dispatch("startApp", this.selectedAccount)
      },
      loadDetails(){
          this.$bvModal.show("all-details")
      },
      saveTimeChanges(){
        //check if end date is null or greater then start date 
        
        if (this.endDate && this.startDate) {
          let edr = false;
          let sdr = false;
          var d1 = new Date(this.startDate);
          var d2 = new Date(this.endDate);
          if (d2 > d1) {
            this.$store.state.registeredWallets[this.selectedAccount].claims[this.appId].endTime = this.endDate
            edr = true
          }else{
            alert("End Date must be greater then Start Date")
          }
          //
          var d1 = new Date(this.startDate);
            var d2 = new Date();
            if (d1 > d2) {
            this.$store.state.registeredWallets[this.selectedAccount].claims[this.appId].startTime = this.startDate
              sdr = true
            }else{
              alert("Start Date must be a future date")
            }
  
            if (sdr && edr) {
            this.$store.state.registeredWallets[this.selectedAccount].claims[this.appId].cStep = 4
            this.$store.state.cStep = 4
            this.$store.dispatch("updateAccounts")
              // alert("Date are configured now")
            }else{
              alert("Dates are not configured correctly, please check dates")
            }
        }
        else{
          alert("Please provide valid date")
        }
        // var same = d1.getTime() === d2.getTime();
        // var notSame = d1.getTime() !== d2.getTime();
      },
      async confirmDeploy(){
        const provider = await detectEthereumProvider();
        if (provider) {
          this.web3 = new Web3(provider);
          provider.enable();
          const chainId = await provider.request({
              method: 'eth_chainId'
            })
         if (chainId == this.userAccount.selectedChain.chainId) {
          this.$store.dispatch("deployContract", {id:this.appId, account:this.selectedAccount})
        }else{
          alert("Please change meta mask network to your Selected "+this.userAccount.selectedChain.name+" and then retry")
        }   
        }   
            
      },
      async validateWallet(){
        if (web3.utils.isAddress(this.selectedToken)) {
          console.log("passed test wallet")
          // let result = await fetch(this.selectedChain.API+'api?module=contract&action=getabi&address='+this.selectedToken+'&apikey='+this.selectedChain.KEY)
          // result = await result.json()
          let result = await this.$store.dispatch("BquerySymbolDecimals",{chain:this.selectedChain.chainId,address:this.selectedToken})
          console.log("result of BquerySymbolDecimals:",result)
          if (result) {
          this.isWalletValid = true
          this.selectedSymbol = result[0].smartContract.currency.symbol
          this.selectedDecimal = result[0].smartContract.currency.decimals
          }else{
            this.isWalletValid = true
          this.manualInput = true
          }
          // if (result.status !== '0') {
          // let contractABI = JSON.parse(result.result);
          // console.log(contractABI)  
          // }else{
          //   alert(result.result)
          
          // }
          
          return
          this.$store.dispatch("loadSymbolForm", {address:this.selectedToken, chain:this.selectedChain})
        } else {
          console.log("uinvalid wallet")
          this.isWalletValid = false
        }
      },
      async saveTokenChanges(){
        if (this.isWalletValid) {
          if (this.selectedDecimal && this.selectedSymbol.length > 1 && this.selectedSymbol.length < 5) {
              this.$store.state.registeredWallets[this.selectedAccount].claims[this.appId].DECIMALS = this.selectedDecimal
              this.$store.state.registeredWallets[this.selectedAccount].claims[this.appId].symbol = this.selectedSymbol
              this.$store.state.registeredWallets[this.selectedAccount].claims[this.appId].TOKEN = this.selectedToken
              this.$store.state.registeredWallets[this.selectedAccount].claims[this.appId].selectedChain = this.selectedChain
              this.$store.state.registeredWallets[this.selectedAccount].claims[this.appId].cStep = 1
  
               this.$store.state.DECIMALS = this.selectedDecimal
              this.$store.state.symbol = this.selectedSymbol
              this.$store.state.cStep = 1
              this.selectedToken = null;
              this.selectedSymbol = null;
              this.selectedDecimal = null;
              this.selectedChain = null;
              this.$store.dispatch("updateAccounts")
          }else{
            alert("invalid Symbol or Decimals")
          }
          
        }else{
          alert("Token COntract Address is not valid")
        }
      },
      async handleFile(e){
        var files = e.target.files || e.dataTransfer.files;
        if (files.length){
          await this.createInput(files[0]);
          
        }else{
          console.log("Invalid file")
        }
          
      },
       async createInput(file) {
        let fileinput = null
                  var reader = new FileReader();
                  var vm = this;
                  reader.onload = async (e) => {
  
                  fileinput = reader.result;
                  this.fileinput = fileinput
                  let config = {}
                  let data = []
                  try {
                  data = this.$papa.parse(this.fileinput,[config]);
                    
                  } catch (error) {
                  alert("Invalid CSV file Format")  
                  }
                  data = data.data
                  this.csvData = {}
                  this.errors = [];
                  this.skipped = [];
                  data.forEach(element => {
                    if (element.length == 2) {
                      let val = Math.round((Number(element[1])*10**this.DECIMALS)).toString(16)
                      let dc = val.split(".")
                      console.log("dc is", dc)
  
                      if (  isNaN(  Number(element[1])  )  ) {
                        let message = `Wallet ${element[0]} Does not Contains A Valid Amount, Entry Will Be Skipped`
                        this.errors.push(message)
                        this.skipped.push(element[0])
                      }
                      
                      else if (this.csvData[String(element[0])]) {
                        let message = `Wallet ${String(element[0])} Was found Duplicate, Entry Will Be Skipped"`
                        this.errors.push(message)
                        this.skipped.push(element[0])
                      }
  
                      else if (!web3.utils.isAddress(element[0])) {
                        let message = `Wallet Address ${element[0]} Was Found As Invalid Wallet Address, Entry Will Be Skipped`
                        this.errors.push(message)
                        this.skipped.push(element[0])
                      }else{
                          this.csvData[String(element[0])] = dc[0]
                      }
  
                    }
                  });
                  this.$bvModal.show("import-result")
                  }
                  reader.readAsText(file);
              },
      loadSampleImage(){
        this.$bvModal.show("sample-image")
      },
      async generateRoot(){
  console.log(this.csvData)
        try {
        let data = JSON.stringify(parseBalanceMap(this.csvData))
        this.$store.dispatch("updateMerkleData", {data,wallet:this.appId})
        } catch (error) {
          alert("Failed to Generate the List")
        }
      },
      setDays(value) { this.days = value},
      setHours(value) { this.hours = value},
      setMinutes(value) { this.minutes = value},
      setSeconds(value) { this.seconds = value},
      timer() {
        var x = setInterval(()=>{
  
        // Get today's date and time
        this.now = new Date().getTime();
          
        // Find the distance between now and the count down date
        if(this.endTime){
          this.expireable = true
          let countDownDate = new Date(this.endTime).getTime()
        this.distance = countDownDate - this.now;
          
        // Time calculations for days, hours, minutes and seconds
        this.setDays(Math.floor(this.distance / (1000 * 60 * 60 * 24)));
        this.setHours(Math.floor((this.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
        this.setMinutes(Math.floor((this.distance % (1000 * 60 * 60)) / (1000 * 60)));
        this.setSeconds(Math.floor((this.distance % (1000 * 60)) / 1000));
            
        // count down is over 
        if (this.distance < 0) {
          clearInterval(x);
          // alert("EXPIRED");
          this.expired = true;
        }
        }else{
          this.expireable = false;
        }
        
      }, 1000);
      },
      copy(text){
    var copyText = text
  
    navigator.clipboard.writeText(copyText);
  
    alert("Copied the text: " + copyText);
    
  
      },
      goTo(){
        window.open("https://claimelly.com", "_blank")
      },
      goTos(){
        window.open("https://hbsc-website-test-qelxg.ondigitalocean.app", "_blank")
      },
      runBquery(){
        this.$store.dispatch("Bquery");
      },
      async checkValue(){
        let val = Web3.utils.toChecksumAddress( this.vCheck ) ;
        let result = this.claimData.claims[ val ];
        if (result !== undefined) {
          let dec = await this.$store.dispatch("checkClaimStatus", val)
          let status = (dec) ? "Claimed" : "UnClaimed"
          console.log(result, dec)
          let amt = result.amount
          amt = (Number(amt) / this.DECIMALS)
          let message = "the wallet "+val+" has a Claim of "+amt+" "+this.symbol+"  and claim status is "+status+""
          alert(message)
        }else{
          let message = ' This wallet is not eligible for Claim'
          alert(message)
        }
        console.log(this.claimData.claims)
      },
      async withdrawNative(){
        this.$store.dispatch("withdrawNative",{sender: this.selectedAccount})
      },
      async withdraw() {
        console.log("running withdraw function")
        if(this.wAmount > 0 && this.wToken.length > 5){
          console.log(this.wAmount)
        let dec = await this.$store.dispatch('withdraw', {
          sender: this.selectedAccount,
          amount: this.wAmount,
          token: this.wToken
        })
        if (dec) {
            this.$store.dispatch('Bquery');
          // await this.$store.dispatch('BalanceCheck',  this.selectedAccount) ;
            this.wAmount = 0
            this.wToken = ''
        }
        }else{
          alert('please provide valid value and address')
        }
      },
        async withdrawAll(){
        let admin = this.admin
        let claimAccountBalance = this.claimAccountBalance
        let symbol = this.symbol
        let message = "Are you sure you want to withdraw "+claimAccountBalance+symbol+" to wallet "+admin
        console.log(message, admin, claimAccountBalance, symbol)
        let d = confirm(message)
        if (d) {
          let dec = await this.$store.dispatch("withdraw", {sender: this.selectedAccount, amount:claimAccountBalance})
        if (dec) {
          await this.$store.dispatch('contractBalance');
          await this.$store.dispatch('BalanceCheck',  this.selectedAccount) ;
  
        }  
        }
        
      },
      async updateAdmin(){
        await this.$store.dispatch("updateAdmin", {wallet:this.changeAdmin, sender: this.selectedAccount})
        this.$store.dispatch('getOwner');
         this.$store.dispatch('getAdmin');
         this.changeAdmin = '' 
      },
     
       onComplete(data) {
        this.$store.state.selectedAccount = data.metaMaskAddress
        console.log(data)
      },
      async fund() {
        await this.$store.dispatch('fundContract',{amount:this.fAmount})
        this.fAmount = 0
        await this.$store.dispatch("contractBalance");
      },
      async setProvider() {
        this.$store.state.provider = await detectEthereumProvider();
        if (this.$store.state.provider) {
          this.web3 = new Web3(this.$store.state.provider);
          this.$store.state.provider.enable();
          const chainId = await this.$store.state.provider.request({
              method: 'eth_chainId'
            })
          this.$store.state.provider.enable();
  
          console.log('chainID', chainId);
          window.ethereum.on('chainChanged', () => {
            this.connect()
          })
          window.ethereum.on('accountsChanged', () => {
            // window.location.reload();
            this.connect()
          })
          
          // 0x38 for mainnet
          // 0x61 for mainnet
          // if (chainId !== "0x38") {
          //   try {
          //   await window.ethereum.request({
          //     method: 'wallet_switchEthereumChain',
          //     params: [{ chainId: '0x38' }], // chainId must be in hexadecimal numbers
          //   });  
          //   } catch (error) {
          //     console.log("Error in changing chain", error)
          //     alert("Please Add BSC network in Meta Mask")
          //   }
            
          //   return false;
          // }
          this.$store.state.provider.enable();
          return true
        } else {
          console.log('Please install MetaMask!');
          return false;
        }
      },
      async connect(){
        let dec = await this.setProvider(); 
        console.log('set ptovidder result', dec)
        if (dec) {
          let user = null;
            let addresses = await window.ethereum.request({method: "eth_requestAccounts",});
                if(addresses.length>0){
                  await web3.eth.getAccounts((error,result) => {
                    console.log(error, result)
                      if (error || result.length < 1) {
                          console.log(error, 'or no accounts');
                      } else {
                        console.log('setting up user account',result[0])
                          user = result[0]
                      }
                  }).then(async () => {
                    if(user){
                      console.log('found user', user)
                      this.$store.state.selectedAccount = user
                      if (this.setupComplete) {
                        const chainId = await this.$store.state.provider.request({
                          method: 'eth_chainId'
                        })
                      if (chainId !== this.userAccount.selectedChain.chainId) {
                        try {
                        await window.ethereum.request({
                          method: 'wallet_switchEthereumChain',
                          params: [{ chainId: this.userAccount.selectedChain.chainId}],
                        });
  
                      // this.$store.dispatch("loadSymbol", this.appId)
                      this.$store.dispatch('getOwner');
                      this.$store.dispatch('getAdmin');
                      // this.$store.dispatch('contractBalance');
                      this.$store.dispatch('Bquery');
                      // this.$store.dispatch('BalanceCheck', user); 
  
                        } catch (error) {
                          console.log("Error in changing chain", error)
                          alert("Please Add "+this.userAccount.selectedChain.name+" in Meta Mask")
                        }
                        
                        return false;
                      }else{
                      // this.$store.dispatch("loadSymbol", this.appId)
                      this.$store.dispatch('getOwner');
                      this.$store.dispatch('getAdmin');
                      // this.$store.dispatch('contractBalance');
                      this.$store.dispatch('Bquery');
                      // this.$store.dispatch('BalanceCheck', user); 
                      }
                       
                      }else{
                        this.registrationProcess() 
                      }
                    }else{
                      console.log(user,'user not found')
                    }
                  })
                  }
          }  
        
      },
      async registrationProcess(){
        // check wallet is in registered list
        // check how much steps completed
        // start the step required to run
        let registered = await this.$store.dispatch("checkWalletAccount", this.appId)
        if (registered) {
          
        }else{
          alert("Account Not Found")
        }
      },
      async claim(value) {
        if(window.ethereum)
        {
          let amt = value
          let dec = await this.$store.dispatch("checkClaimStatus", this.selectedAccount)
          console.log("result of claim status check is", dec)
          if (!dec) {
              let de = await this.$store.dispatch('hasClaimed', {account:this.selectedAccount,value:amt} )
              console.log('transaction result', de)    
          } else {
            alert("Already Claimed")
          }
        
        }else{
          console.log('not found meta mask')
          alert("Install Metamask")
        }
      },
      
     
    }
    }
  </script>
  
  <style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Orbitron&display=swap');
  .orbit{
  font-family: 'Orbitron', sans-serif;
  
  }
  .btn-mode{
    right: 14px;
      top: -8px;
      padding: 6px;
      border-radius: 0px 33px 0px 11px;
  }
  .btn-mode:focus {
    outline: none;
    box-shadow: none;
  }
  .dark{
        background: black;
  }
  .bg-darks{
        background: transparent;
  }
  .light, .bg-whites{
        background: #FFF;
  }
  .text-whites{color:black;}
  .text-darks{color:#FFF;}
  .dark .shadow-cc{
      border-radius:33px;
      box-shadow: 0px -1px 19px #888888;
  }
  .dark .shadow-c{
    border: 0px !important;
      box-shadow: 3px 0px 9px 1px #888
  }
  .dark{
    border: 0px !important;
      box-shadow: 0px -1px 19px #888888;
  }
  .light{
    border: 0px !important;
      box-shadow: 0px -1px 19px lightslategrey;
  }
  .dark .input-group-text{
    border:0px !important;
  }
  @font-face {
        font-family: 'Arial';
        font-style: normal;
        font-weight: 400;
        src: local('Arial');
        font-display: swap;
      }
body {
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%; 
        margin: 0;

        padding: 25px; }
    .admin{
        width: 470px;
        margin-left: auto;
        margin-right: auto;
    }
    .navbar {background-color: rgba(0, 0, 0, 0.97);
            height: 71px;}
    .orange, .orange-new {color: #F3BA2F;}
    .orange, .black {font-size: 3vw;
        line-height: 3vw;
                    font-weight: 550;}
    .headtext {margin-left: 11.5%;
              margin-top: 2%;}
    .headtext2 {margin-left: 11.5%;
               font-size: 90%;}
    .headbar {background-color: #F3BA2F;
          background-image: url('../../public/img/hbsc2.png');
          background-repeat: no-repeat;
          background-position: right;
          margin-left: 10%; 
          margin-top: 2.6%; 
            width: 75.1%;
            height: 72px;
            border-radius: 5px;}
    
    .hxymoney {margin-left: 6.5%; 
           /* margin-top: 0.9%;  */
           width: 120px;
           height: 46px;}
           .claimelly{
                /* background:#FFFF; */
                /* max-width:500px;  */
                /* max-height:500px;  */
                /* height:500px;  */
                position:relative;
                min-height:500px;
                max-width:600px;
                margin-top:50px;
                border-radius: 32px;
                padding: 32px;
                padding-bottom: 67px;
        }
        @media only screen and (max-width: 600px) {
                .claimelly {
                padding: 6vw;
                margin-left:2px;
                }
                .btn-drk, .btn-drks{
                        width: 99% !important;
                        /* border-radius: 22px ; */
                        background-position: center;
                }
                .c-text
                {
                  font-size:15px;
                }
              }
        .hr{
                width: 63%;
                border-top: 2.5px solid gray;
                margin-top: 24px;
            }
            .det_spec {
                font-family: "Arial";
                color: rgb(164, 164, 164);
                line-height: 1.667;
                text-align: justifyLeft;
              }
              .det_spec-mob {
                font-family: "Arial";
                color: rgb(164, 164, 164);
                line-height: 1.667;
                font-size:13px;
                text-align: justifyLeft;
              }
              
              .btn-prim-big{
                cursor: pointer;
                background-image:url(../../public/img/btn-pri.png);
                display: inline-block;
                height: 55px;
                /* width: 208px; */
                background-size: contain;
                background-repeat: no-repeat;
                top: 5px;
                right: -2px;
                font-family: "Arial";
                color: rgb(255, 255, 255);
                font-weight: bold;
                line-height: 1.075;
                text-align: center;
                padding: 12px;
                      
        }
              .btn-pri{
                cursor: pointer;
                background-image:url(../../public/img/btn-pri.png);
                display: inline-block;
                /* height: 55px; */
                width: 208px;
                background-size: contain;
                background-repeat: no-repeat;
                top: 5px;
                right: -2px;
                font-family: "Arial";
                color: rgb(255, 255, 255);
                font-weight: bold;
                line-height: 1.075;
                text-align: center;
                padding: 9px;
                      
        }
        .btn-prima{
                cursor: pointer;
                background-image:url(../../public/img/btn-pri.png);
                display: inline-block;
                height: 55px;
                width: 208px;
                background-size: auto;
                background-color: transparent;
                border:none;
                background-repeat: no-repeat;
                font-family: "Arial";
                color: rgb(255, 255, 255);
                font-weight: bold;
                line-height: 1.075;
                text-align: center;
                padding: 9px;
        }
        .inp-plain{
                border-radius: 1px 22px 22px 1px;
            border-left: 2px solid  purple;
            border-top: 0px;
            border-right: 0px;
            border-bottom: 0px;
            margin-left: 7px;
            margin-right: 2px;
            font-size:14px;
            height: 30px;
            margin-top: 3px;
        }
        .btn-pri-two{
                cursor: pointer;
                background-image:url(../../public/img/btn-pri.png);
                display: inline-block;
                height: 38px;
                width: 208px;
                background-size: cover;
                background-repeat: no-repeat;
                top: 5px;
                right: -2px;
                font-family: "Arial";
                color: rgb(255, 255, 255);
                font-weight: bold;
                line-height: 1.075;
                text-align: center;
                padding: 9px;
                      
        }
        .btn-drk{
                cursor: pointer;
                background-image:url(../../public/img/btn-dark.png);
                display: inline-block;
                height: 38px;
                width: 208px;
                background-size: cover;
                background-repeat: no-repeat;
                top: 5px;
                right: -2px;
                font-family: "Arial";
                color: rgb(255, 255, 255);
                font-weight: bold;
                line-height: 1.075;
                text-align: center;
                padding: 9px;
        }
        .btn-drks{
                cursor: pointer;
                background-image:url(../../public/img/btn-pri.png);
                display: inline-block;
                height: 38px;
                width: 208px;
                background-size: cover;
                background-repeat: no-repeat;
                top: 5px;
                right: -2px;
                font-family: "Arial";
                color: rgb(255, 255, 255);
                font-weight: bold;
                line-height: 1.075;
                text-align: center;
                padding: 9px;
        }
        .inp-border{
                /* border:1px solid grey; */
                /* border-top:1px solid grey; */
                /* border-bottom:1px solid grey; */
                /* border-right:0px solid grey; */
                border-radius: 32px;
        }
        .inp-border-two{
                border-left:0px solid grey;
                border-top:1px solid grey;
                border-bottom:1px solid grey;
                border-right:1px solid grey;
                border-radius: 32px;
        }
        .txBgPri{
                -webkit-background-clip: text;
                 -webkit-text-fill-color: transparent;
                  background-image: url(../../public/img/btn-pri.png);
                  background-position: top;
        }
        .btn-tab{
                bottom: -30px;
                right: -13px;
                        font-family: "Arial";
                        color: rgb(74, 74, 74);
                        font-weight: bold;
                        text-decoration: underline;
                        line-height: 1.333;
                      
        }
        .btn-pri-mob{
                cursor: pointer;
                background-image: url(../../public/img/btn-pri.png);
                display: inline-block;
                height: 42px;
                width: 129px;
                font-size: 12px;
                background-size: contain;
                background-repeat: no-repeat;
                top: 15px;
                right: -2px;
                font-family: "Arial";
                color: rgb(255, 255, 255);
                font-weight: bold;
                line-height: 1.075;
                text-align: center;
                padding: 5px;
        }
        .claimelly .icon{
                max-width: 100px;
                max-width: 30%;
            float: left;
        }
        .claimelly .icon-mob{
                max-width: 100px;
                max-width: 47%;
            float: left;
        }       
        
        
    .main-container {background-color: rgba(0,0,0,0.97);
            color:  white;
            margin-left: 10%; 
            margin-top: 1.5%; 
            padding-bottom: 219px;
            width: 75.1%;
            height: 330px;
            border-radius: 10px;}
    
    .headbar img {position: relative;}
    
    .address {display: inline;
            font-weight: bold; 
            position: relative; 
            left: -1.2%;
            top: 30%;
            padding-left: 2%;
            color: black;
            font-family: Arial;
            font-size: 14px;
            }
    
    .text1 {display: inline;
          position: relative; 
          margin-right: 1%;
          top: 36%;
          font-weight: 540;
          float: right;
          padding-left: 2%;
          color: black;
          font-family: Arial;
          font-size: 14px;}
    
    .hxylogo {position: relative; 
             float: right;
             top: 24%; 
             height: 30%;
             margin-right: -1%;
    
          }
    
    .hxyamount {position: relative; 
              margin-right: -0.9%; 
              font-weight: bold;
              top: 38%;
              float: right;
              display: inline;
              padding-left: 2%;
              color: black;
              font-family: Arial;
              font-size: 14px;}
    
    .hxylabel {position: relative; 
              margin-right: 7%;
              float: right;
              top: 38%;
              display: inline;
              padding-left: 2%;
              color: black;
              font-family: Arial;
              font-size: 14px;}
    
    .metafox {
            height: 25%; 
            position: relative; 
            top: 33%;}
    .img1 {width: 50px;
          height: 52px;
          position: absolute;
          margin-left: -5%;
          margin-top: 1%;}
    
    .header {font-weight: 450;
            margin-left: 5%;
            position: relative;
            top: 7%;}
    
    .summary {color: white;
            margin-left: 5%;
            font-size: 94%;
            width: 90%;
            position: relative;
            top: 15%;
            word-break: normal;}
    
    .claim {position: relative;
      top: 25%;
      margin-left: 28%;
      width: 50%;}
    
    .ca {font-size: 92%;
      font-weight: 500;}
    
    .cc {
      font-size: 92%;
      font-weight: 500;}  
    
    .horizontal_bar{position: relative;
                  top: 27%;
                  height: 3.1px;
                  width:470px;
                  margin-left: 25%;}
    
    .ca2 {font-size: 92%;
      font-weight: 500;
      padding-left: 22px;
      padding-top: 10px;}
    
    .cc2 {
        width: 150px;
          height: 30px;
          color: black;
          background-color: #F3BA2F;
          border: none;
          border-radius: 2px;
          font-size: 75%;
          position: relative;
          font-weight: 550;
          }  
    
    .cc2:hover {
      background-color: white;
      color: #F3BA2F;
      cursor: pointer;}
    
    .claimRow2 {position: relative;
      top: 28%;
      margin-left: 28%;
      width: 50%;}
    
    .logohxy {height: 26px;}
    .link{cursor: pointer;}
    .claim_amount {font-weight: bold; 
                font-size: 85%;
                position: relative;
                top: -9px;}
    
    .label_2 {color: grey;
            font-size: 85%;
            position: relative;
            top: -9px;
            padding-left: 2%;}
    
    .time_note {margin-left: 63.7%;
              position: absolute;
              margin-top: -1.3%;
              font-size: 94%;
              color: grey;
              }
    
    .counter {
        right: 15%;
    position: absolute;
    margin-top: -2.5%;
    font-size: 90.5%;
    font-weight: 500;    
            }
    
    .connect_wallet {
            position:relative;
            z-index:999999;
            float: right;
            margin-top: -55px;
            margin-right: 30px;
            border: none;
        padding: 6px 16px;
        font-size: 0.875rem;
        min-width: 64px;
        box-sizing: border-box;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 500;
        line-height: 1.75;
        border-radius: 4px;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.87);
    background-color: #F3BA2F;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
        height: auto;
                    }
    
    .connect_wallet:hover {cursor: pointer;
                          box-shadow: 1px 1px 3px #e68a00;}
    
    .foxwallet {height: 67%;
              position: absolute;
              top: 9.9%;
              left:77.7% ;
              }
    
    .welcome {display: inline;
                      position: relative;
                      top: 30%;
                      padding-left: 2%;
                      padding-right: 0.5%;
                      color: black;
                      font-family: Arial;
                      font-size: 14px;
                      margin-left: 2%;}
    
    .hxypng {display: inline;
                      margin-right: -1%;
                      position: relative;
                      top: 10%;
                      padding-left: 2%;
                      color: grey;
                      font-family: Arial;
                      font-size: 14px;
                      }
    
    
    /* @media only screen and (max-width: 1275px){
                    .connect_wallet {font-size: 70%;
                                    }   
    } */
    
    @media only screen and (max-width: 1200px){
                    .text1 {margin-left: 150px;}   
    }
    
    @media only screen and (max-width: 1075px){
                    .claim {top: 15%;}
                    .horizontal_bar {top: 17%;}
                    .claimRow2 {top: 18%;}
                    .header {top: 7%;}
                    .summary {color: white;
                            font-size: 85%;
                            position: relative;
                            top: 4%;
                            word-break: break-word;
                        }
                    /* .connect_wallet {font-size: 70%;
                                    }    */
    }
    
    
    @media only screen and (max-width: 1053px){
                    .img1 {display: none;}
                    .time_note {font-size: 87%;
                               margin-top: -3.2%;
                               margin-left: 59%;}
                    .counter {font-size: 80%;
                             margin-top: -3.4%;
                             margin-left: 64%;}
                    .text1 {display: none;}
                    .cc2 {width: 70%; font-size: 70%;}
                    
                    .foxwallet {left: 70%;}           
    }
    
    @media only screen and (max-width: 695px){
                     body {
                        background-position: left bottom;
                        }
                    .time_note {font-size: 80%;
                               margin-top: -5.8%;
                               margin-left: 57%;}
                    .counter {font-size: 70%;
                             margin-top: -3%;
                             margin-left: 1%;}
                    .hxypng {top: 14%;}
                    .address {font-size: 62%;}
                    .hxylabel {font-size: 62%; top: 44%;}
                    .hxyamount {font-size: 62%; top: 44%;}
                    .text1 {display: none;}
                    .claim {margin-left: 5%;width:81%;}
                    .horizontal_bar {margin-left: 5%;
                                    width:88%;}
                    .claimRow2 {margin-left: 7%;width: 68%;}
                    .hxymoney {width: 130px;
                              height: 45px;
                              margin-top: 2.5%;}
                    .cc2 {width: 90%; font-size: 65%;}
                    .claim {top: 10%;}
                    .horizontal_bar {top: 12%;}
                    .claimRow2 {top: 13%;}
    
                    .connect_wallet {margin-top: -8%;
                                    
                                    margin-left: 73%;}  
                    .foxwallet {left: 75%;}  
                    .headbar {height: 55px;}  
    }
    
    @media only screen and (max-width: 669px){
                    .time_note {font-size: 60%;}
                    .counter {font-size: 50%;}
                    .connect_wallet {
                                    
                                    border-radius: 7;}  
                    .foxwallet {left: 80%;}         
    }
    
    @media only screen and (max-width: 603px){
                    .ca {font-size: 82%;
                         font-weight: 500;}
                    .cc {font-size: 82%;
                         font-weight: 500;}
                    .ca2 {font-size: 82%;
                         font-weight: 500;}
                    .cc2 {font-size: 65%;
                         font-weight: 500;
                         width: 100%;}
    
                    .claim {top: 7%;}
                    .horizontal_bar {top: 7%;}
                    .claimRow2 {top: 7%;}
                    .connect_wallet {
                                   
                                    border-radius: 7px;
                                    margin-top: -10.5%;
                                    }  
                    .foxwallet {left: 77%;}         
    }
    
    @media only screen and (max-width: 586px){
                    
                    .foxwallet {left: 80%;
                               }         
    }
    
    @media only screen and (max-width: 519px){
                    .welcome {font-size: 69%;}
                    .hxylabel {margin-right: 1.5%;}
                    .connect_wallet {}  
                    .foxwallet {left: 77%;}         
    }
    
    @media only screen and (max-width: 426px){
        .form-control{
                font-size:13px;
        }
                    .connect_wallet {
                                    margin-top: -9.7%;
                                    margin-left: 66%;} 
                    .welcome {font-size: 70%;} 
                    .foxwallet {left: 82%;}   
                    .time_note {margin-top: -8.5%;
                               margin-left: 50%;}
                    /* .counter {margin-top: -10.5%;
                             margin-left: 62%;} */
                             .counter{
                                margin-top: -3.5%;
                                margin-left: 0%;
                             }
                    .hxypng {
                            top: 10%;
                            margin-right: 0%;}
                    .address {font-size: 46%;
                             width: 20px;
                             overflow: hidden;
                             text-overflow: ellipsis;}
                    .hxylabel {
                              top: 42.4%;
                              font-size: 51%;
                              margin-right: 1%;}
                    .hxyamount {
                               font-size: 62%;
                               margin-right: 1%;}
                    .header{font-size: 17px;
                           top: 6%;}
                    .summary {font-size: 93%;}
    
                }
    
    @media only screen and (max-width: 321px){
                        .address {font-size: 46%;}
                        .time_note {margin-top: -8.7%;
                                   margin-left: 47%;}
                    .counter {margin-top: -11.5%;
                             margin-left: 59.5%;}
    }
    .headbar-new 
    {
        background-color: #F3BA2F;
        background-image: url('../../public/img/hbsc2.png');
        background-repeat: no-repeat;
        background-position: right;
        /* margin-left: 10%;  */
        /* margin-top: 2.6%;  */
        /* width: 75.1%; */
        height: 72px;
        border-radius: 5px;
        }
        .counter-new{
                position: absolute;
                right: 15px;
                margin-top: -2.5%;
                font-size: 90.5%;
                font-weight: 500;
                top:-11px;
        }
        .main-new{
                background-color: rgba(0,0,0,0.97);
                color: white;
                height: 330px;
                border-radius: 10px;
        }
        #app{
                /* width:103vw; */
                /* overflow-x: hidden; */
        }
        .orange-new, .black-new {
                font-size: 5vw;
                line-height: 5vw;
                            font-weight: 550;}
                            .t-bold{
                                    font-weight:700;
                            }                           
                            [v-cloak] {display: none;}
                            *{
                                opacity: 1;
                                animation-name: fadeInOpacity;
                                animation-iteration-count: 1;
                                animation-timing-function: ease-in;
                                animation-duration: 1s;
                        }
                        
                            
.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 2s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
  </style>